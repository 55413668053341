import { CompanyRole } from 'shared/models/CompanyRole.model'
import { MovementType } from 'components_sites_calendar/helpers/eventHelpers'

export enum ShipmentRoleType {
  RECEIVER = 'receiver',
  SENDER = 'sender',
  FORWARDER = 'forwarder',
  CARRIER = 'carrier',
}

export default interface ShipmentRole extends CompanyRole<ShipmentRoleType> {
  markedForDeletion?: boolean
}

export function buildShipmentRole(attributes: ShipmentRole): ShipmentRole {
  return {
    ...attributes,
  }
}

export function getSiteCompanyShipmentRole(movementType: MovementType): ShipmentRoleType {
  switch (movementType) {
    case 'inbound':
      return ShipmentRoleType.RECEIVER
    case 'outbound':
      return ShipmentRoleType.SENDER
    default:
      throw new Error(`Unknown movement type: ${movementType}`)
  }
}
